import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Chip, Grid, FormControl, TextareaAutosize } from '@material-ui/core';
import { logException } from '../../../services/appInsights';
import { btNavyBlueColor } from '../../../assets/jss/nextjs-material-kit-pro';
import { arrayBufferToBase64 } from '../../../util/stringUtil';
import EssayInstructions from './EssayInstructions';
import EssayNote from './EssayNote';

const styles = makeStyles((theme) => ({
  sectionSpacer: {
    height: 50,
    padding: '10px 0',
  },
  rowSpacer: {
    height: 30,
  },
  textRowLabelSpacer: {
    [theme.breakpoints.down('sm')]: {
      height: 70,
      paddingTop: 5,
    },
    [theme.breakpoints.up('sm')]: {
      height: 50,
      paddingTop: 8,
    },
  },
  textRowSpacer: {
    [theme.breakpoints.up('sm')]: {
      height: 50,
    },
  },
  printedContent: {
    '@media print': {
      marginBottom: 10,
    },
    '@media screen': {
      display: 'none',
    },
  },
  unprintedContent: {
    '@media print': {
      display: 'none',
    },
    '@media screen': {},
  },
  textAreaFont: {
    fontSize: '0.938rem',
    fontFamily: 'Source Sans Pro, Helvetica, Roboto, Arial, sans-serif',
    padding: 10,
  },
  alignPrintedContent: {
    lineHeight: '25px',
    verticalAlign: 'top',
  },
  marginTop15: {
    marginTop: 15,
  },
  textArea25RowSpacer: {
    [theme.breakpoints.only('xs')]: {
      height: 1000,
      paddingTop: 5,
    },
    [theme.breakpoints.only('sm')]: {
      height: 850,
      paddingTop: 5,
    },
    [theme.breakpoints.only('md')]: {
      height: 580,
      paddingTop: 5,
    },
    [theme.breakpoints.only('lg')]: {
      height: 600,
      paddingTop: 8,
    },
  },
  error: {
    color: '#FA0000',
    fontSize: '0.90rem',
    fontWeight: 600,
  },
  essayOptions: {
    fontSize: '0.90rem',
    fontWeight: 500,
    color: btNavyBlueColor,
  },
}));

export default function Essay(props) {
  const { form, setForm, essayFile, setEssayFile, appId, disabled, status } = props;
  const classes = styles();

  const [uploadError, setUploadError] = React.useState();

  const checkExtensionForDocument = (file) => {
    return /\.(doc|docx|pdf|txt)$/i.test(file[0].name);
  };

  const confirmFileUpload = async (file) => {
    async function setEssayDetails() {
      try {
        if (checkExtensionForDocument(file)) {
          const data = arrayBufferToBase64(await file[0].arrayBuffer());
          const filedetails = {
            'aftercare_application_id': appId,
            'essay': data,
            'file_type': file[0].type,
            'filename': file[0].name,
          };
          setEssayFile(filedetails);
          setUploadError('');
        } else {
          setUploadError('Error: The file could not be uploaded. Only Word Documents, Text Files, and PDF files can be uploaded.');
        }
      } catch (error) {
        logException(error);
        console.log('Error setting essay details:', error);
      }
    }
    setEssayDetails();
  };

  const downloadEssay = async (essay) => {
    // const file = await convertFile(essay);
    let a = document.createElement('a'); //Create <a>
    a.href = `data:${essay.browserFile.type};base64,${arrayBufferToBase64(await essay.browserFile.arrayBuffer())}`;
    a.download = essay.browserFile.name;
    a.click();
  };

  const removeEssay = () => {
    if (status === 'created' && !disabled) {
      setEssayFile(null);
    }
  };

  return (
    <Box sx={{ width: '95%' }}>
      <section>
        <EssayInstructions />
      </section>

      <section className={classes.marginTop15}>
        <EssayNote />
        <React.Fragment>
          <Grid container direction="row">
            <Grid item xs={12} className={classes.unprintedContent}>
              {essayFile && (
                <summary className={classes.essayOptions} style={{ marginBottom: 10 }}>
                  File Uploaded:
                  <Chip
                    style={{ margin: 5 }}
                    key={essayFile && essayFile.id}
                    label={essayFile && essayFile.filename}
                    onDelete={() => {
                      removeEssay();
                    }}
                    color="primary"
                    onClick={() => downloadEssay(essayFile)}
                  />
                </summary>
              )}

              {!essayFile && status === 'created' && !disabled && (
                <div>
                  <label className={classes.essayOptions}>Upload a file</label>{' '}
                  <input type="file" disabled={disabled || status !== 'created'} onChange={(e) => confirmFileUpload(e.target.files)} />
                  {essayFile && essayFile.name}
                  <br />
                  <aside className={classes.error}>{uploadError}</aside>
                  <label className={classes.essayOptions}>or type essay in the space below.</label>
                </div>
              )}
            </Grid>
          </Grid>
        </React.Fragment>
        {!essayFile && (
          <Grid container direction="row">
            <Grid item xs={12} className={classes.textArea25RowSpacer + ' ' + classes.unprintedContent}>
              <FormControl fullWidth>
                <TextareaAutosize
                  aria-label="Successful Futures application essay"
                  minRows={25}
                  maxRows={25}
                  maxLength={5000}
                  value={form.essay}
                  onChange={(event) => setForm({ ...form, essay: event.target.value })}
                  disabled={disabled}
                  className={classes.textAreaFont}
                  style={{ color: disabled && 'rgba(0, 0, 0, 0.4)' }}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        <p
          className={classes.printedContent}
          style={{ padding: 10, whiteSpace: 'pre-wrap', border: form.essay && '1px inset #000', fontStyle: !form.essay && 'italic' }}
        >
          {essayFile ? 'Download attached file.' : form.essay || null}
        </p>
      </section>
    </Box>
  );
}

Essay.propTypes = {
  form: PropTypes.object,
  setForm: PropTypes.func,
  essayFile: PropTypes.object,
  setEssayFile: PropTypes.func,
  appId: PropTypes.number,
  disabled: PropTypes.bool,
  status: PropTypes.string,
};
