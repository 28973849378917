import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(()=> (
  {
    instructions: {
      color: '#000',
    },
  }
));

export default function EssayInstructions() {
  const classes = styles();

  return (
    <>
      <label className={classes.instructions}>
        Type a 1000-word essay using the following guidelines.
      </label>
      <br />
      <label className={classes.instructions}>The essay should have four parts:</label>
      <label className={classes.instructions}>
        <ol>
          <li>
            Start by introducing yourself and briefly describe your post-graduation plans. You could use a summarized
            version of what you
            already wrote as part of the application above.
          </li>
          <li>
            Reflect on your experiences at Boys Town, answering questions such as: What is your greatest lesson(s)
            learned at Boys Town?
            Who has been instrumental in teaching you how to be successful?
          </li>
          <li>
            Explain the needs you have in order to achieve the plan from the introductory paragraph and address
            questions such as: What do
            you anticipate your future temptations/struggles will be? How will you use what you have learned to overcome
            obstacles in
            order to be successful? How are you stronger today than you were before?
          </li>
          <li>
            Finally, close your essay communicating what you have done to prepare for your future (both personally, as
            well as with school and employment) after you depart/ed Boys Town. Express thanks for the opportunity to
            apply, describe what success means to you, and how the Boys Town support will help you be successful in your
            future life.
          </li>
        </ol>
      </label>
    </>
  );
};

