import React from 'react';
import { Box, Typography } from '@material-ui/core';

const AboutMeInstructions = () => {
  return (
    <Box component="div" className="row" mt={2} mb={4}>
      <Typography variant="body1" paragraph>
        Please answer all questions below in complete sentences <b>BEFORE</b> sending any recommendation requests. Your
        responses will be included as an introductory paragraph with each invitation.
      </Typography>
      <Typography variant="body1" paragraph>
        You only need to complete this section once. You may edit your responses at any time. However, any changes will
        apply to all pending invitations, including those sent out before the changes were made.
      </Typography>
      <Typography variant="body1" paragraph>
        Once the About Me section is complete, fill out the Recipient Information and click INVITE to send the request.
      </Typography>
    </Box>
  );
};

export default AboutMeInstructions;