import { Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { btNavyBlueColor } from '../../../assets/jss/nextjs-material-kit-pro';

const styles = makeStyles(()=> (
  {  unprintedContent: {
      '@media print': {
        display: 'none',
      },
      '@media screen': {},
    },
    essayOptions: {
      fontSize: '0.90rem',
      fontWeight: 500,
      color: btNavyBlueColor,
    },
    instructions: {
      color: '#000',
    },
  }
));
export default function EssayNote() {
  const classes = styles();

  return (<Grid container direction="row">
    <Grid item xs={12} className={classes.unprintedContent} style={{ padding: '5px 0 10px 0' }}>
      <label className={classes.essayOptions}>
        NOTE: You can either upload an essay (Word document, text file, or PDF) or type your essay in the space
        provided.
      </label>
    </Grid>
  </Grid>);
}