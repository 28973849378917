import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import AboutMeContent from './RecommendationComponents/AboutMeContent';
import EditIcon from '@material-ui/icons/Edit';
import AboutMeInstructions from './RecommendationComponents/AboutMeInstructions';
import PropTypes from 'prop-types';
import { callApi } from '../../util/apiWrapper';
import { appInsights, logException } from '../../services/appInsights';
import { ABOUT_ME } from '../../constants/api';
import { PUT } from '../../constants/method';
import { isNonEmpty } from '../../util/stringUtil';
import SectionHeader from './RecommendationComponents/SectionHeader';

const AboutMe = ({ senior, store, auth, handleAfterAboutMeUpdate }) => {
  const [allowEdit, setAllowEdit] = useState(false);
  const [formData, setFormData] = useState({
    post_graduation_plan: '',
    accomplishments: '',
    extracurricular_activities: '',
  });
  const [initialFormData, setInitialFormData] = useState(formData);

  useEffect(() => {
    if (!senior) return;
    const { post_graduation_plan, accomplishments, extracurricular_activities } = senior || {
      post_graduation_plan: '',
      accomplishments: '',
      extracurricular_activities: '',
    };
    setFormData({
      post_graduation_plan,
      accomplishments,
      extracurricular_activities,
    });
    setInitialFormData({
      post_graduation_plan,
      accomplishments,
      extracurricular_activities,
    });
  }, [senior]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const canSubmit = (() => {
    const isNotEmpty =
      isNonEmpty(formData?.post_graduation_plan) && isNonEmpty(formData?.accomplishments) && isNonEmpty(formData?.extracurricular_activities);

    if (!isNotEmpty) return false;
    return Object.keys(formData).some((key) => formData[key] !== initialFormData[key]);
  })();

  const handleClickEdit = () => {
    setAllowEdit(true);
  };

  const handleClose = (event, reason) => {
    event.preventDefault();
    if (reason === 'backdropClick') {
      setAllowEdit(true);
    } else {
      setFormData(initialFormData);
      setAllowEdit(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    async function persistAboutMeInfo() {
      try {
        store.setLoading(true);
        store.setSearching(true);

        const request = {
          id: store.senior.id,
          ...formData,
        };
        const response = await callApi(auth, ABOUT_ME, PUT, request);
        if (response.result) {
          const { post_graduation_plan, accomplishments, extracurricular_activities } = response
            ? response.result
            : {
              post_graduation_plan: '',
              accomplishments: '',
              extracurricular_activities: '',
            };
          setInitialFormData({
            post_graduation_plan,
            accomplishments,
            extracurricular_activities,
          });
          handleAfterAboutMeUpdate(response.result);
        }
        appInsights.trackTrace({ message: `Saved AboutMe for user id: ${store.senior.id}` });

        store.setSearching(false);
        store.setLoading(false);
      } catch (error) {
        logException(error);
      } finally {
        store.setSearching(false);
        store.setLoading(false);
      }
    }

    persistAboutMeInfo().then(() => {
      setAllowEdit(false);
    });
  };

  return (
    <React.Fragment>
      <Grid container align="left">
        <Box>
          <AboutMeInstructions />
          <SectionHeader title="About Me" icon={EditIcon} onTitleClick={handleClickEdit} />
          {!allowEdit && (
            <AboutMeContent formData={formData} handleChange={handleChange} handleSubmit={handleSubmit}
                            enableSubmit={canSubmit} isDialog={false} />
          )}
          <AboutMeContent
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            enableSubmit={canSubmit}
            open={allowEdit}
            handleClose={handleClose}
            isDialog={true}
          />
        </Box>
      </Grid>
    </React.Fragment>
  );
};

AboutMe.propTypes = {
  senior: PropTypes.object.isRequired,
  store: PropTypes.object,
  auth: PropTypes.object,
  classes: PropTypes.object.isRequired,
  handleAfterAboutMeUpdate: PropTypes.func.isRequired,
};

export default AboutMe;
