import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'baseline',
  },
  title: {
    cursor: 'pointer',
    fontFamily: 'sans-serif',
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '40px',
    textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const SectionHeader = ({ title, icon: Icon, onTitleClick }) => {
  const classes = useStyles();

  return (
    <Box className={classes.header} mb={1}>
        <Typography
          variant="h4"
          component="h2"
          className={classes.title}
          onClick={onTitleClick}
          gutterBottom
        >
          {title}
        </Typography>
        {Icon && (
          <IconButton className={classes.icon} aria-label="edit" color="primary" onClick={onTitleClick}>
            <Icon />
          </IconButton>
        )}
    </Box>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.elementType,
  onTitleClick: PropTypes.func,
};

export default SectionHeader;