import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, useMediaQuery, withStyles } from '@material-ui/core';
import AboutMeInputFields from './AboutMeInputFields';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textTransform: 'none' }}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const AboutMeContent = (
  {
    formData,
    handleChange,
    handleSubmit,
    enableSubmit,
    open,
    handleClose,
    isDialog,
  }) => {
  const isLargeScreen = useMediaQuery('(min-width:1024px)');
  return (
    <React.Fragment>
      {isDialog ? (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="lg"
                fullWidth
                PaperProps={{
                  style: {
                    position: isLargeScreen ? 'absolute' : 'relative',
                    left: '0px',
                  },
                }}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            About Me
          </DialogTitle>
          <DialogContent dividers sx={{ textAlign: 'left' }}>
            <AboutMeInputFields formData={formData} handleChange={handleChange} />
          </DialogContent>
          <DialogActions>
            <Box className="row">
              <Button style={{ width: '110px' }} color="primary" variant="contained" onClick={handleSubmit}
                      disabled={!enableSubmit}>
                Save
              </Button>
            </Box>
            <Box className="row">
              <Button style={{ width: '110px' }} color="default" variant="contained" onClick={handleClose}>
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      ) : (
        <Box sx={{ textAlign: 'left' }}>
          <AboutMeInputFields formData={formData} disabled={true} />
        </Box>
      )}
    </React.Fragment>
  );
};

AboutMeContent.propTypes = {
  formData: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  enableSubmit: PropTypes.bool,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isDialog: PropTypes.bool.isRequired,
};

export default AboutMeContent;