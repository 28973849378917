import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { Box } from '@material-ui/core';
import { AboutMeFieldNames } from '../../../constants/general';

const programNames = {
  scholarships: 'Scholarships',
  tradeLife: 'Trade Life',
  housing: 'Housing',
};

const getDisplayPrograms = (userPrograms) => {
  if (!userPrograms) return '';
  const programsToDisplay = Object.keys(userPrograms)
    .filter((key) => userPrograms[key])
    .map((key) => programNames[key]);
  if (programsToDisplay.length === 0) return '';
  if (programsToDisplay.length === 1) return `${programsToDisplay[0]}`;
  return `${programsToDisplay.slice(0, -1).join(', ')} and ${programsToDisplay[programsToDisplay.length - 1]}`;
};

const StudentAboutMe = ({ studentFirstName, studentLastName, programs, aboutMe, classes }) => {

  const paragraphs = [
    `Hi, my name is ${studentFirstName} ${studentLastName} and I am applying for ${getDisplayPrograms(
      programs,
    )} with Successful Futures. Before you proceed with writing the recommendation, I’d like to share a few key details about myself that might be helpful.`,
    `<p><i>${AboutMeFieldNames.planText} </i> </p> ${aboutMe.post_graduation_plan}`,
    `<p><i>${AboutMeFieldNames.accomplishmentText} </i> </p> ${aboutMe.accomplishments}`,
    `<p><i>${AboutMeFieldNames.activityText} </i> </p> ${aboutMe.extracurricular_activities}`,
    'Thank you so much for writing a letter on my behalf.',
    `${studentFirstName} ${studentLastName}`,
  ];

  return (
    <Box className={classes.introContainer} style={{ marginBottom: '45px' }}>
      <Box mt="5px">
        <Divider style={{ height: '2px' }} />
        {paragraphs.map((text, index) => (
          <Box component="div" key={index} className={classes.intro} dangerouslySetInnerHTML={{ __html: text }} />
        ))}
      </Box>
    </Box>
  );
};

StudentAboutMe.propTypes = {
  studentFirstName: PropTypes.string.isRequired,
  studentLastName: PropTypes.string.isRequired,
  programs: PropTypes.shape({
    scholarships: PropTypes.bool,
    tradeLife: PropTypes.bool,
    housing: PropTypes.bool,
  }).isRequired,
  aboutMe: PropTypes.shape({
    post_graduation_plan: PropTypes.string.isRequired,
    accomplishments: PropTypes.string.isRequired,
    extracurricular_activities: PropTypes.string.isRequired,
  }).isRequired,
  classes: PropTypes.object,
};

export default StudentAboutMe;
