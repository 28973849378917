import React, { useState } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
  },
}));

const InputField = ({
    label,
    name,
    value,
    onChange,
    isRequired = true,
    isDisabled = false,
    multiple = false,
    maxLength = 1000,
  }) => {
  const [touched, setTouched] = useState(false);
  const classes = useStyles();

  const handleBlur = () => {
    setTouched(true);
  };

  return (
    <TextField
      id={name}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={handleBlur}
      variant="outlined"
      fullWidth
      required={isRequired}
      inputProps={{ maxLength }}
      margin="normal"
      multiline={multiple}
      minRows={multiple ? (isDisabled ? 2 : 4) : 1}
      sx={{ textAlign: 'left' }}
      error={touched && (!value || value.trim() === '')}
      helperText={isRequired && touched && (!value || value.trim() === '') ? 'This field is required' : ''}
      disabled={isDisabled}
      className={classes.root}
    />
  );
};

InputField.prototype = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  multiple: PropTypes.bool,
  maxLength: PropTypes.number,
};
export default InputField;